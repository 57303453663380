/* eslint-disable no-return-await */
import { makeObservable, action, override, runInAction } from 'mobx';
import { CommonService } from './common';
import Home from './api.interface';
import { HomeModel } from './home.model';
import { UC_TOGGLE_STATUS } from '../userInfo/userInfo.interface';

export class HomeService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @override
  async getHomeInfo(): Promise<HomeModel> {
    const { code, result } = await this.post(Home.RefactorAPI.get_homepage);
    if (code === 0) {
      runInAction(() => {
        this.home = {
          ...this.home,
          ...result,
        };
      });
    }
    return result || this.home;
  }

  @action
  async saveHomeInfo(params: HomeModel) {
    this.home = {
      ...this.home,
      ...params,
    };
  }

  @action
  async getLimitAdjustInfo() {
    const { code, result } = await this.post(Home.API.get_limit_adjust_info);
    if (code === 0) {
      const {
        limit_is_change,
        limit_change_time,
        from_id,
        from_limit,
        to_id,
        to_limit,
        temp_limit,
      } = result;

      runInAction(() => {
        this.home = {
          ...this.home,
          onboardingLimitAdjust: {
            limit_is_change,
            limit_change_time,
            from_id,
            from_limit,
            to_id,
            to_limit,
          },
          temporaryLimitAdjust: temp_limit?.[0] ?? {},
        };
      });
    }
  }

  @action
  async markLimitAdjust(params: { from_id?: number; to_id?: number; limit_type?: number }) {
    return await this.post(Home.API.commit_limit_change, {
      body: params,
    });
  }

  @override
  async updateUserConfig(params: {
    toggle_status?: UC_TOGGLE_STATUS;
    disburse_percentage?: number;
    fee_change_type?: number;
    toggle_off_type?: number;
  }) {
    return await this.post(Home.RefactorAPI.update_user_config, {
      body: params,
      manual: true,
    });
  }

  @override
  async getLatestDisbursedGroups() {
    return await this.post(Home.RefactorAPI.get_user_latest_disbursed_groups);
  }

  /**
   *
   * 单独更新设置的比例，也可以请求方式getHomeInfo来更新
   * @param {number} [percentage=0]
   * @memberof HomeService
   */
  @override
  async updatePercentage(percentage: number) {
    this.home = {
      ...this.home,
      schedule_disburse_percentage: percentage,
    };
  }
}
