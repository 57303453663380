import { makeObservable, override } from 'mobx';
import { CommonService } from './common';
import { UpcomingListModel } from './model';
import Upcoming from './interface';

export class UpcomingService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @override
  async fetchInfo() {
    // 如果已经标记当前状态为hasmore：false
    if (!this.hasMore || !this.loaded) return;
    this.loaded = false;

    const { code, result } = await this.post(Upcoming.RefactorAPI.get_upcoming, {
      body: {
        pagination: {
          page_index: this.page_index + 1,
          page_size: this.page_size,
          cursor:
            this.dataList.length > 0 ? this.dataList?.[this.dataList.length - 1]?.id ?? '0' : '0',
        },
      },
    });
    if (code === 0) {
      const { page_info, list = [], ...info } = result;

      this.dataList = list;
      this.hasMore = page_info.has_more;
      this.page_index = page_info.page_index;
      this.upcomingState = {
        ...this.upcomingState,
        ...result,
      };
      this.loaded = true;
      return result;
    }
    this.loaded = true;
    this.hasMore = false;
  }
}
