import { makeObservable, override } from 'mobx';
import { CommonService } from './common';
import { Toast } from '@credit/tips';
import dayjs from '@/utils/dayjs';
import { formatMessage } from '@/utils/locale';
import Order from './api.interface';

export class OrderService extends CommonService {
  constructor() {
    super();
    makeObservable(this);
  }

  @override
  async getOrderList() {
    const { page_size, start_time, end_time, status, loading } = this.orderList;
    if (loading) return;

    if (
      start_time &&
      end_time &&
      dayjs(start_time.getTime()).add(7, 'month').set('date', 0).valueOf() < end_time.getTime()
    ) {
      Toast.fail(formatMessage({ id: 'transaction.over_time_range' }));
      this.orderList = {
        ...this.orderList,
        list: [],
        dataSource: [],
      };
      return;
    }

    this.orderList = {
      ...this.orderList,
      loading: true,
    };

    const params = {
      start_time: start_time ? dayjs(start_time).startOf('date').unix() : null,
      end_time: end_time ? dayjs(end_time).endOf('date').unix() : null,
      pagination: {
        cursor: '0',
        page_index: 1,
        page_size,
      },
      status,
    };
    const res = await this.post(Order.RefactorAPI.get_order_list, {
      body: {
        ...params,
      },
    });
    if (res.code === 0) {
      const {
        list,
        total_amount,
        page_info: { total, has_more, page_size: pageSize, page_index },
      } = res?.result;
      const newList = list ?? [];
      this.orderList = {
        ...this.orderList,
        list: newList,
        dataSource: newList,
        has_more: has_more ?? false,
        total: total ?? 0,
        total_amount: total_amount ?? 0,
        page_index: page_index ?? this.orderList.page_index,
        page_size: pageSize ?? this.orderList.page_size,
        loading: false,
      };
    } else {
      Toast.fail(res.msg, 2);
      this.orderList = {
        ...this.orderList,
        loading: false,
      };
    }
  }

  @override
  async loadMoreOrderList() {
    const {
      list,
      total_amount: originTotalAmount,

      page_index,
      page_size,
      start_time,
      end_time,
      status,

      loading,
      has_more,
    } = this.orderList;
    if (loading || !has_more) return;

    this.orderList = {
      ...this.orderList,
      loading: true,
    };

    const params = {
      start_time: start_time ? dayjs(start_time).startOf('date').unix() : null,
      end_time: end_time ? dayjs(end_time).endOf('date').unix() : null,
      pagination: {
        cursor: list[list?.length - 1]?.plan_id ?? '0',
        page_index: page_index + 1,
        page_size,
      },
      status,
    };
    const response = await this.post(Order.RefactorAPI.get_order_list, {
      body: {
        ...params,
      },
    });
    if (response?.code === 0) {
      const { list: originList } = this.orderList;
      const {
        list: result_list,
        total_amount,
        page_info: { total, has_more: hasMore, page_size: pageSize, page_index: pageIndex },
      } = response?.result;
      const newList = originList.concat(result_list ?? []);
      this.orderList = {
        ...this.orderList,
        list: newList,
        dataSource: newList,
        has_more: hasMore ?? false,
        total: total ?? 0,
        total_amount: pageIndex > 1 ? originTotalAmount : total_amount ?? 0,
        page_index: pageIndex ?? this.orderList.page_index,
        page_size: pageSize ?? this.orderList.page_size,
        loading: false,
      };
    } else {
      this.orderList = {
        ...this.orderList,
        loading: false,
      };
    }
  }

  @override
  async getOrderInfo(plan_id: string) {
    const { code, result } = await this.post(Order.RefactorAPI.get_order_detail, {
      body: { plan_id },
    });
    if (code === 0) {
      this.orderInfo = { ...this.orderInfo, ...result };
    }
    return result || this.orderInfo;
  }
}
